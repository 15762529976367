import {
  Button,
  Checkbox,
  DialogActions,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";

import { addContent } from "../../../../Redux/Actions/contentAction";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";
const VimeoWidget = ({ isOpen, onClose, onTakeData, isIcon,folderId }) => {
  const { user } = useSelector((state) => state.User);
  const dispatch = useDispatch();
  // const { currentFolder } = useSelector(state => state.Folder);
  const [items, setItems] = useState([]);
  const [name, setName] = useState("");
  const [url, setUrl] = useState("");
  const [previewOpen, setPreviewOpen] = useState(false);
  const [item, setItem] = useState({});

  // React.useEffect(() => {
  //   handleClickOpen();
  // }, [isOpen, onClose]);

  // const handleClickOpen = () => {
  //   // setOpen(true);
  //   if (isOpen) {
  //     setName("");
  //     setUrl("");
  //   }
  //   if (onClose) {
  //     setName("");
  //     setUrl("");
  //   }
  // };

  // const handleClose = () => {
  //   // setOpen(false);
  // };

  const handleAdd = () => {
    const array = url.split("/");
    const vmId = array.pop();
    if (name == "" || url == "") {
      toast.error("(*) fields are required.");
      return;
    }
    if (array.includes("vimeo.com") && vmId) {
      setItems((previous) => [...previous, { title: name, url, id: vmId }]);
      onTakeData({ title: name, url, id: vmId, isIcon });
      const htmlVimeo = `
      <iframe
      title="Vimeo Video"
      width="329px"
      height="288px"
      style={{ border: "1px solid black", borderRadius: "10px" }}
      src={"https://player.vimeo.com/video/{{id}}?autoplay=1"}
      allowFullScreen
    ></iframe>
      `;
      const emUrl = `https://player.vimeo.com/video/{{id}}?autoplay=1`;
      const htmltostr = htmlVimeo
        .replace(/"/g, '\\"')
        .replace(/\n/g, "\\n")
        .toString();
      dispatch(
        addContent({
          name,
          type: "widget",
          widgetCat: "vimeo",
          widgetIcon: isIcon,
          url: emUrl.replace("{{id}}", vmId),
          html: htmltostr
            .replace(/\\"/g, '"')
            .replace(/\\n/g, "\n")
            .replace("{{id}}", vmId),
          userId:user._id,
          folderId
        })
      );
      setName("");
      setUrl("");
      setItem({});
      setPreviewOpen(false);
      toast.success("Successfully Loaded!");

      onClose();
    } else {
      setName("");
      setUrl("");
      toast.error("Only Vimeo link valid.");
    }
  };

  // const handlePreviewOpen = () => {
  //   const array = url.split("/");
  //   const vmId = array.pop();

  //   if (array.includes("vimeo.com") && vmId) {
  //     setItem({ title: name, url, id: vmId });
  //     toast.success("Successfully Loaded!");
  //     setPreviewOpen(true);
  //   } else {
  //     toast.error("Alert! There Is Some Error - Check it out");
  //   }
  // };

  // const handlePreviewClose = () => {
  //   setPreviewOpen(false);
  //   setItem({});
  // };
  return (
    <>
            <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            fontSize: "18px",
            fontWeight: 600,
            padding:"15px",
            marginTop:"8px"
          }}
        >
         <span>Vimeo </span> 
         
        
          <span style={{border:"none",background:"transparent",color:"black",cursor:"pointer"}} onClick={onClose}> <CloseIcon/></span>
        </div>
        <div
         style={{
          minHeight: "6dvh",
        fontWeight:"600",
        fontSize:"16px",
          display: "flex",
          justifyContent: "center",
          alignItems:"center"
        }}>Vimeo URL</div>
      <div
         style={{
          
          width: "17dvw",
          display: "flex",
          flexDirection: "column",
          // alignItems: "center",
          padding: 20,
          gap: 10,
        }}
      >
          <span  style={{
         color:"rgba(0,0,0,0.5)",
          }}>Vimeo Name</span>
        {/* <DialogContentText sx={{ paddingTop: 3, fontWeight: "bold" }}>
          Name &#8727;
        </DialogContentText> */}
        <input
          id="outlined-basic"
          label="Your Name Here"
          variant="outlined"
          fullWidth
          placeholder="Vimeo name"
          style={{ height: "35px", borderRadius:"8px", border:"1px solid rgba(0,0,0,0.3)",paddingLeft:"17px"}}
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
      </div>
      <div
        style={{
          width: "17dvw",
          display: "flex",
          flexDirection: "column",
          // alignItems: "center",
          padding: 20,
          gap: 10,
        }}
      >
        {/* <DialogContentText sx={{ fontWeight: "bold" }}>
          URL &#8727;
        </DialogContentText> */}
        <span  style={{
         color:"rgba(0,0,0,0.5)",
          }}>Vimeo Url</span>
        <input
          id="outlined-basic"
          label="URL"
          variant="outlined"
          fullWidth
          placeholder="Vimeo Url"
          style={{ height: "35px", borderRadius:"8px", border:"1px solid rgba(0,0,0,0.3)",paddingLeft:"17px"}}
          value={url}
          onChange={(e) => setUrl(e.target.value)}
        />
      </div>
      {/* <div
        style={{
          display: "flex",
          marginTop: 10,
          alignItems: "center",
        }}
      >
        <DialogContentText sx={{ fontWeight: "bold" }}>
          Mute:{" "}
        </DialogContentText>
        <Checkbox sx={{ marginLeft: 4 }} />
      </div> */}
      <DialogActions
        sx={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: 2,
          marginTop: 1,
        }}
      >
        {/* <div>
          <Button
            variant="contained"
            //   onClick={handlePreviewOpen}
            sx={{ backgroundColor: "#016AFE" }}
          >
            Preview
          </Button>
        </div> */}
        <div style={
            { display: "flex",
              justifyContent: "center",
              alignItems:"center"
              , width: "18.5dvw"}
           }>
          {/* <Button onClick={() => onClose(false)}>Cancel</Button> */}
          <Button
            variant="contained"
            onClick={handleAdd}
            style={{background:'var(--primary-color)', width: "17dvw",height:"4vh",padding:20,borderRadius:"8px"}} 
          >
            Save
          </Button>
        </div>
      </DialogActions>
    </>
  );
};

export default VimeoWidget;
