
import { api } from "../../api/api"
import {
    ADD_PREVILLIAGE_RRQUEST,
    ADD_PREVILLIAGE_SUCCESS,
     EDIT_PREVILLIAGES,
     GET_PREVILLIAGE_SUCCESS,
      PREVILLIAGE_FLAG_OFF,
      PREVILLIAGE_LIST_EXPANDED,
      PREVILLIAGE_LIST_UNEXPANDED,
      REMOVE_PREVILLIAGE
} from "../constant"

export const AddPrevilliages = (id,previlliage) => {
    return async (dispatch) => {
        dispatch({
            type: ADD_PREVILLIAGE_RRQUEST,
            payload: ''
        })
        const { status, data } = await api.post('/previlliage/addprevilliage', {parentId:id,previlliage })
        if (status == 201) {
            dispatch({
                type: ADD_PREVILLIAGE_SUCCESS,
                payload: data.prev
            })
        }
    }
}
export const FlagOffPrevilliage = () => {
    return async (dispatch) => {
        dispatch({
            type: PREVILLIAGE_FLAG_OFF,
            payload: ''
        })
    }
}
export const GetPrevilliages = (id) => {
    return async (dispatch) => {
        const { status, data } = await api.get(`/previlliage/getprevilliages/${id}`);
        if (status == 200) {
            dispatch({
                type: GET_PREVILLIAGE_SUCCESS,
                payload: data.prev
            })
        }
    }
}
export const expandedList = (id) => {
    return async (dispatch) => {
        dispatch({
            type: PREVILLIAGE_LIST_EXPANDED,
            payload: id
        })
    }
}
export const unExpandedList = (id) => {
    return async (dispatch) => {
        dispatch({
            type: PREVILLIAGE_LIST_UNEXPANDED,
            payload: id
        })
    }
}
export const RemovePrevilliage=(id)=>{
    return async (dispatch)=>{
        const {status,data}=await api.get(`/previlliage/removeprevilliage/${id}`);
        if(status==201){
            dispatch({
                type:REMOVE_PREVILLIAGE,
                payload:id
            })
        }
    }
}
export const EditPrevilliages=(parentId,id,previlliage)=>{
    return async (dispatch) => {
        const {status,data}=await api.patch(`/previlliage/editprevilliages/${id}`,{parentId,previlliage});
        console.log(data)
        if(status==201){
            dispatch({
                type:EDIT_PREVILLIAGES,
                payload:{id,updatedPrevilliages:data.updatedPrevilliages}
            })
        }
    }
}