import { TextField, Box, Container } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { forgetPassword, userFlagOff } from "../../Redux/Actions/userAction";
import { useDispatch, useSelector } from "react-redux";
import loginBackgroundIMG from "../../Assets/images/login-background.png";
import salarlogo from "../../Assets/images/HD.png";
import "./login.css";
import toast from "react-hot-toast";
import Loader from "../../components/loader/Loader";
import { useNavigate } from 'react-router-dom';
function ForgetPassword() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isEmailTransfored, isEmailNotTransfored, loading } = useSelector(
    (state) => state.User
  );
  const Goback = () => {
    navigate('/login');
  };
  const [email, setEmail] = useState("");
  const submitEmail = () => {
    dispatch(forgetPassword(email));
  };
  useEffect(() => {
    if (isEmailTransfored) {
      toast.success(
        "Email has sended with Link successfully, please check your inbox"
      );
      dispatch(userFlagOff());
    }
    if (isEmailNotTransfored) {
      toast.error("Email is not valid. Please provide valid email.");
      dispatch(userFlagOff());
    }
  }, [isEmailTransfored, isEmailNotTransfored]);
  return (
    <>
      <Box
        sx={{
          minHeight: "100vh",
          maxWidth: "100vw",
          display: "flex",
          backgroundImage: `url(${loginBackgroundIMG})`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          backgroundSize: "cover",
          position: "relative",
          margin: 0,
          padding: 0,
          boxSizing: "border-box",
        }}
      >
        {loading && (
          <>
            <Box
              sx={{
                height: "100vh",
                width: "100vw",
                position: "absolute",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                background: "rgba(0,0,0,0.5)",
                top: 0,
                left: 0,
                bottom: 0,
                right: 0,
                zIndex: "100000",
              }}
            >
              <Loader/>
              {/* <img src="/images/spiner.gif" /> */}
            </Box>
          </>
        )}
           <Container maxWidth="xl">
            <Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "100vh",
                  position: "relative",
                }}
              >
                <Box
                  sx={{
                    width: { lg: "100%", md: "100%", sm: "73%", xs: "73%" },
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                  }}
                >
                  <div className="forget-password-form">
                    <div className="login-form-header">
                      <div className="logo-sec">
                        <div className="logo">
                        <img
                          src={salarlogo}
                          style={{
                            height: "100%",
                            width: "100%",
                            borderRadius: "10px",
                            objectFit:'contain'
                          }}
                        />
                        </div>
                      </div>
                    </div>
                    <div className="login-form-body">
                      <div className="title">
                        <div className="login-icon"></div>
                        <div className="title-text size-title-text-2">
                          Forget Password
                        </div>
                      </div>
                      <div className="input-fields">
                        <TextField
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          type={"email"}
                          variant={"standard"}
                          label={"Email"}
                          style={{
                            borderBottom: "1px solid #551FFF",
                            color: "#551FFF",
                          }}
                          fullWidth
                        />
                      </div>
                      <div className="button-field">
                        <button
                          type={"button"}
                          className="btn-login"
                           onClick={Goback}
                        >
                          cancle
                        </button>
                        <button
                          type={"button"}
                          className="btn-login"
                          onClick={submitEmail}
                          style={{margin:"2px"}}
                        >
                          send
                        </button>
                      </div>
                      {/* <div className="resend-email flex justifyContentCenter alignItemCenter">
Resend <Link>Email</Link>
</div> */}
                    </div>
                  </div>
                </Box>
                {/* <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    width: { lg: "50%", md: "50%", sm: "0%", xs: "0%" },
                  }}
                >
                  <div className="text-block">
                    <p className="main-heading">
                      We Make Your Business Digitalize
                    </p>
                    <p className="sub-heading">
                      If you have question please contact support@leedoit.com?
                    </p>
                  </div>
                </Box> */}
              </Box>
              <div className="bottom-box"></div>
            </Box>
          </Container>
        {/* <Box
          sx={{
            height: "100dvh",
            width: "70dvw",
            // border: "1px solid black",
            backgroundImage: `url(${loginBackgroundIMG})`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            backgroundSize: "cover",
            display: { lg: "block", md: "block", sm: "none", xs: "none" },
          }}
        ></Box> */}
        {/* <Box
          sx={{
            height: "100dvh",
            width: {
              xl: "30dvw",
              lg: "30dvw",
              md: "30dvw",
              sm: "100dvw",
              xs: "100dvw",
            },
            backgroundImage: {
              xl: "none",
              lg: "none",
              md: "none",
              sm: "none",
              xs: "none",
            },
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            backgroundSize: "cover",
            // border: "1px solid black",
          }}
        >
       
        </Box> */}
      </Box>
    </>
  );
}

export default ForgetPassword;
