import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addContent, deleteContent } from "../../../../Redux/Actions/formAction";
import "../images/image.css";
import { imgUrl } from "../../../../api/api";
function Widget(props) {
  const dispatch = useDispatch();
  const { contentForm, allContent } = useSelector((state) => state.Form);
  const checkBox = (e) => {
    const { value, checked } = e.target;
    if (checked) {
    //   const _widget=allContent.find(c=> c.id===value)
    //   const newData={name:_widget.name,id:_widget.id,type:_widget.type,
    //   url:_widget.widget.url,
    //   html:_widget.widget.html,
    //   widgetCat:_widget.widget?.widgetCat,
    //   widgetIcon:_widget.widget?.widgetIcon
    //   }
    //  console.log(allContent.find(c=> c.id===value))
      dispatch(
        addContent(
          props.pId,
          allContent.find(c=> c.id===value)
        )
      );
    } else {
      dispatch(deleteContent(props.pId, value));
    }
  };
  return (
    <>
      <div className="image-container">
        {allContent.map(
          (data) =>
            data.type === "widget" && (
              <>
                <div className="image-content-cards" key={data.id}>
                 {/* <div style={{margin:'0 10px'}}> */}
                
                 <input
                    type="checkbox"
                    name={data.name}
                    value={data.id}
                    checked={data.selected}
                    onChange={checkBox}
                    id={`checkbox_${data.id}`}
                    style={{height:'15px',width:'15px',display:'none'}}
                    hidden
                  />
                 <label htmlFor={`checkbox_${data.id}`} className="upper-layer"></label>
                 {/* </div> */}
                 <div style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
                      <img src={`${data.widget?.widgetIcon}`} style={{height:'80%',width:'80%'}} alt="" />
                    </div>
                    <div className="image-content-card-footer">
                      <p>{data?.name?.substring(0,20)}</p>
                    </div>
                </div>
              </>
            )
        )}
      </div>
    </>
  );
}

export default Widget;
