import React ,{useState} from 'react'
import PhotoCameraBackIcon from '@mui/icons-material/PhotoCameraBack';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import {AppsOutlined, Folder} from '@mui/icons-material'
import { imgUrl } from '../../../api/api';
import ScheduleSendIcon from "@mui/icons-material/ScheduleSend";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Menu, MenuItem } from '@mui/material';
function Widget({ data,addSchedule, deleteSingleContent,onOpenFolderModel }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
 
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (type, obj) => {
    setAnchorEl(null);
  };
  return (
    <>
      <div className="content-item" key={data.key}>
        <div className="content-image">
          <div className="content-del-icon">
          <MoreVertIcon
              style={{ color: "var(--primary-color)" }}
              onClick={handleClick}
              aria-controls={open ? "content-menu" : undefined}
              aria-haspopup={"true"}
              aria-expanded={open ? "true" : undefined}
              id="content-menu-btn"
            />
            <Menu
              sx={{ zIndex: "1" }}
              id={"content-menu"}
              anchorEl={anchorEl}
              open={open}
              MenuListProps={{
                "aria-labelledby": "content-menu-btn",
              }}
              onClose={handleClose}
              keepMounted
              anchorOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
            >
              <MenuItem
                onClick={() => {
                  handleClose();
                  deleteSingleContent(data.id);
                }}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <DeleteOutlineIcon style={{ color: "var(--primary-color)" }} />
              </MenuItem>
              <MenuItem
              onClick={()=>{
                handleClose();
                onOpenFolderModel(data.id)}}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Folder
                  sx={{ color: "var(--primary-color)", cursor: "pointer" }}
                />
              </MenuItem>
            </Menu>
          </div>
          <img src={`${data.widget.widgetIcon}`} style={{height:'100%',width:'100%',objectFit:'contain'}} alt="" />
        </div>
        <div className="content-bottom">
          <p>{data?.name?.split("--")[0]?.substring(0,20)}</p>
          <p style={{
            display: 'flex',
            alignItems: 'center'
          }}>
            <span>
              <AppsOutlined sx={{
                fontSize: '17px',
                color:"var(--primary-color)"
              }} />
            </span>
            <span>{data.type}</span>
          </p>
        </div>
      </div>
    </>
  )
}

export default Widget