import { Button, DialogTitle, TextField } from "@mui/material";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addContent } from "../../../../Redux/Actions/contentAction";
import toast from "react-hot-toast";
import AppWidgetForm from "../../component/models/AppWidgetForm";
import PreviewEmbed from "./PreviewEmbed";
import CloseIcon from "@mui/icons-material/Close";
function EmbedWidget({onClose,folderId}) {
  const { user } = useSelector((state) => state.User);
  const dispatch = useDispatch();
  const [input, setInput] = useState({ name: "", url: "" });
  const [openPreview, setOpenPreview] = useState(false);
  const onGetInput = (e) => {
    const {name,value} = e.target;
    setInput((prev)=>({...prev,[name]:value}))
  };
  const onSubmit = () => {
    const isWebPage = isHTMLPage(input.url);
    if (input.name=="" && input.url=="") {
      toast.error("(*) fields are required.");
      return;
    }
    if (isWebPage) {
      // setItems((previous) => [...previous, { title: name, inputValue }]);
      // onTakeData({ title: name, inputValue, isIcon });

      const htmltostr = input.url
        .replace(/"/g, '\\"')
        .replace(/\n/g, "\\n")
        .toString();
      dispatch(
        addContent({
          name: input.name,
          contenttype: "widget",
          widgetCat: "embed",
          widgetIcon: '/images/embedIcon.png',
          url: "",
          html: htmltostr.replace(/\\"/g, '"').replace(/\\n/g, "\n"),
          userId:user?._id,
          folderId
        })
      );
       
      toast.success("Successfully Loaded!");
      // setOpen(false);
      onClose();
    } else {
      // setOpen(false);

      toast.error("Valid html required.");
    }
  };
  const isHTMLPage = (text) => {
    const htmlPattern = /<!DOCTYPE html>[\s\S]*<\/html>/i;
    const cssPattern = /<style\b[\s\S]*<\/style>/i;
    const jsPattern = /<script\b[\s\S]*<\/script>/i;

    const hasHTML = htmlPattern.test(text);
    const hasCSS = cssPattern.test(text);
    const hasJS = jsPattern.test(text);

    return hasHTML || hasCSS || hasJS;
  };
  return (
    <>
    <div>
      {/* <DialogTitle
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          borderBottom: "3px solid var(--primary-color)",
          fontSize: "18px",
          fontWeight: 700,
        }}
      >
        Embed Widget
      </DialogTitle>
      <div
        style={{
          minHeight: "10dvh",
          width: "30dvw",
          display: "flex",
          justifyContent: "center",
          // border:'1px solid black'
        }}
      >
        <div
          style={{
            height: "100px",
            width: "100px",
          }}
        >
          <img
            src="/images/embedIcon.png"
            style={{ height: "100%", width: "100%" }}
          />
        </div>
      </div> */}
                  <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            fontSize: "18px",
            fontWeight: 600,
            padding:"15px",
            marginTop:"8px"
          }}
        >
         <span>Embed </span> 
         
        
          <span style={{border:"none",background:"transparent",color:"black",cursor:"pointer"}} onClick={onClose}> <CloseIcon/></span>
        </div>
        <div
         style={{
          minHeight: "6dvh",
        fontWeight:"600",
        fontSize:"16px",
          display: "flex",
          justifyContent: "center",
          alignItems:"center"
        }}>Embed URL</div>
      <div
      style={{
          
        width: "17dvw",
        display: "flex",
        flexDirection: "column",
        // alignItems: "center",
        padding: 20,
        gap: 10,
      }}
      >
         <span  style={{
         color:"rgba(0,0,0,0.5)",
          }}>Embed Name</span>
        <input
          name={"name"}
          value={input.name}
          onChange={onGetInput}
          variant="outlined"
          label="Enter Name"
          style={{ height: "35px", borderRadius:"8px", border:"1px solid rgba(0,0,0,0.3)",paddingLeft:"17px"}}
          placeholder="Embed Name"
          fullWidth
        />
        <div
        style={{
          
          width: "17dvw",
          display: "flex",
          flexDirection: "column",
          // alignItems: "center",
          // padding: 20,
          gap: 10,
        }}
        >
 <span  style={{
         color:"rgba(0,0,0,0.5)",
          }}>Embed HTML</span>

        <input
          name={"url"}
          value={input.url}
          onChange={onGetInput}
          multiline
          variant="outlined"
          style={{ height: "35px", borderRadius:"8px", border:"1px solid rgba(0,0,0,0.3)",paddingLeft:"17px"}}
          label="Enter Html"
          placeholder="Enter Html"
          fullWidth
        />
        </div>
      </div>
      <div
      style={{
        width: "17dvw",
        display: "flex",
        flexDirection: "column",
        // alignItems: "center",
        padding: 20,
        gap: 10,
      }}
      >
        {/* <Button
          onClick={() => setOpenPreview(true)}
          variant={"contained"}
          title="Preview"
          style={{background:'var(--primary-color)'}}
        >
          Preview
        </Button> */}
        <Button
           onClick={onSubmit}
          variant={"contained"}
          title="Submit"
          style={{background:'var(--primary-color)'}}
        >
          Submit
        </Button>
      </div>
    </div>
    <AppWidgetForm
    maxWidth={'lg'}
    isOpen={openPreview}
    onClose={()=>setOpenPreview(false)}
    >
<PreviewEmbed url={input.url} onClose={()=>setOpenPreview(false)} onConfirm={()=>onSubmit()} />
    </AppWidgetForm>
    </>
  );
}

export default EmbedWidget;
