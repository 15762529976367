import { Dialog, DialogContent, DialogTitle, TextField } from '@mui/material'
import React, { useState } from 'react'
import { CreateFolder } from '../../Redux/Actions/folderAction';
import { useDispatch } from 'react-redux';

function FolderModel({open,onClose,module,parent,userId}) {
    const dispatch=useDispatch();
    const [folderName,setFolderName]=useState('');

  return (
  <>
  <Dialog
maxWidth="sm"
fullWidth={true}
open={open}
onClose={() => onClose()}
>
<DialogTitle>Create Folder</DialogTitle>
<DialogContent>
  <div style={{ margin: "20px 0" }}>
    <div
      style={{
        display: "flex",
        gap: "10px",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <TextField
        variant="standard"
        value={folderName}
        onChange={(e) => setFolderName(e.target.value)}
        label="Title"
        fullWidth
        sx={{
          label: {
            color: "blue",
            fontSize: "14px",
            color: "#92959E",
          },
        }}
      />
      <button
        style={{ marginTop: "40px" }}
        className="btn-save-content"
        onClick={()=>{
          dispatch(CreateFolder({name:folderName,module,parent,userId}))
          onClose()
        }}
      >
        Create
      </button>
    </div>
  </div>
</DialogContent>
</Dialog>
  </>
  )
}

export default FolderModel
