import React, { useEffect, useState } from "react";
import Layout from "../../components/layout/Layout";
import {
  Box,
  Button,
  Container,
  DialogActions,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Dialog,
  DialogContent,
  DialogTitle,
  Paper
} from "@mui/material";
import PrevilliageForm from "./Component/PrevilliageForm";
import { AddPrevilliages, EditPrevilliages, GetPrevilliages, RemovePrevilliage } from "../../Redux/Actions/PrevilliageAction";
import { useDispatch, useSelector } from "react-redux";
import RoleCardComponent from "./Component/RoleCardComponent";
import ConfirmationModel from "../../components/confirmmodel/ConfirmationModel";
import { BorderColorOutlined } from "@mui/icons-material";

function Role({ user }) {
    const dispatch=useDispatch();
    const { previlliages}=useSelector(state=>state.Previlliage);
    const [addOpenModel, setAddOpenModel] = useState(false);
    const [addOpenInviteModel, setAddOpenInviteModel] = useState(false);
    const [roleOpenModel, setRoleOpenModel] = useState(false);
    const [roleManageOpenModel, setRoleManageOpenModel] = useState(false);
    const [ids, setIds] = useState('');
    const [singlePrevId, setSinglePrevId] = useState('')
    const [openEditRoleModel, setOpenEditRoleModel] = useState(false);
    const [editPrev, setEditPrev] = useState({});
    const [openConfirmModel, setOpenConfirmModel] = useState(false);
    const [isEditMode, setIsEditMode] = useState(false);
    const [prevData, setPrevData] = useState({
        name: '',
        screen: {
            canCreate: false,
            canEdit: false,
            canDelete: false
        },
        content: {
            canCreate: false,
            canEdit: false,
            canDelete: false
        },
        playlist: {
            canCreate: false,
            canEdit: false,
            canDelete: false
        },
        folder: {
            canCreate: false,
            canEdit: false,
            canDelete: false
        }
    })

    useEffect(() => {
        // if (!user.isMember) {
            // dispatch(getMembers(user._id));
            dispatch(GetPrevilliages(user._id));
        // }
    }, [])

    const submitPrevilages = () => {
        // console.log({newVal:prevData})
        dispatch(AddPrevilliages(user?._id, prevData));
        setPrevData({
            name: '',
            screen: {
                canCreate: false,
                canEdit: false,
                canDelete: false
            },
            content: {
                canCreate: false,
                canEdit: false,
                canDelete: false
            },
            playlist: {
                canCreate: false,
                canEdit: false,
                canDelete: false
            },
            folder: {
                canCreate: false,
                canEdit: false,
                canDelete: false
            }
        })
        setRoleOpenModel(false);
        setIsEditMode(false);
    }

    const onSubmitEditRole = () => {
        // dispatch(MemberRoleUpdate(ids, singlePrevId));
        setIds('');
        setSinglePrevId('');
        setOpenEditRoleModel(false);
    }
    const onDeletePrevilliage = id => {
        dispatch(RemovePrevilliage(id));
    }
    const onEditPrevilliage = id => {
        // console.log(id);
        setIds(id);
        const pr = previlliages.find(p => p._id == id);
        Object.keys(pr).forEach(key => {
            if (key == 'name') setEditPrev((prev) => ({ ...prev, [key]: pr[key] }))
            if (key == 'screen') setEditPrev((prev) => ({ ...prev, [key]: { ...pr[key] } }))
            if (key == 'content') setEditPrev((prev) => ({ ...prev, [key]: { ...pr[key] } }))
            if (key == 'playlist') setEditPrev((prev) => ({ ...prev, [key]: { ...pr[key] } }))
            if (key == 'folder') setEditPrev((prev) => ({ ...prev, [key]: { ...pr[key] } }))
        })
        setIsEditMode(true);
        setOpenConfirmModel(true);
        //   console.log({pr,prevData,editPrev})
    }
    const onConfirmEditPrevilliage = () => {
        // console.log(editPrev)
        setPrevData({ ...editPrev });
        setOpenConfirmModel(false);
        setRoleOpenModel(true);
    }
    const onCancelEditPrevilliage = () => {
        setEditPrev({});
        setIds('');
        setPrevData({
            name: '',
            screen: {
                canCreate: false,
                canEdit: false,
                canDelete: false
            },
            content: {
                canCreate: false,
                canEdit: false,
                canDelete: false
            },
            playlist: {
                canCreate: false,
                canEdit: false,
                canDelete: false
            },
            folder: {
                canCreate: false,
                canEdit: false,
                canDelete: false
            }
        })
        setIsEditMode(false);
        setOpenConfirmModel(false);
    }
    const onSubmitEditPrevilliage = () => {
        // console.log({edit:prevData});
        dispatch(EditPrevilliages(user?._id, ids, prevData));
        setPrevData({
            name: '',
            screen: {
                canCreate: false,
                canEdit: false,
                canDelete: false
            },
            content: {
                canCreate: false,
                canEdit: false,
                canDelete: false
            },
            playlist: {
                canCreate: false,
                canEdit: false,
                canDelete: false
            },
            folder: {
                canCreate: false,
                canEdit: false,
                canDelete: false
            }
        })
        setIds('');
        setIsEditMode(false);
        setRoleOpenModel(false);
    }
    const cancelRoleOpenModel = () => {
        setIsEditMode(false);
        setRoleOpenModel(false);
        setPrevData({
            name: '',
            screen: {
                canCreate: false,
                canEdit: false,
                canDelete: false
            },
            content: {
                canCreate: false,
                canEdit: false,
                canDelete: false
            },
            playlist: {
                canCreate: false,
                canEdit: false,
                canDelete: false
            },
            folder: {
                canCreate: false,
                canEdit: false,
                canDelete: false
            }
        })
    }
    // const handleUserInput = (e) => {
    //     const { name, value } = e.target;
    //     setUserData((prev) => ({ ...prev, [name]: value }));
    // }
    const handleSwitchInput = (e) => {
        const { name, checked } = e.target;
        const keys = name.split('-');
        setPrevData((prev) => ({ ...prev, [keys[0]]: { ...prev[keys[0]], [keys[1]]: checked } }))
    }
  return (
    <>
      <Layout title="Role/Previlliages" user={user}>
        <Container maxWidth={"lg"}>
          <Grid container mt={2} sx={{ backgroundColor: "#ffffff" }}>
            <Box
              sx={{
                height: "100%",
                width: "100%",
                // border: "1px solid black",
                borderRadius: "10px",
              }}
            >
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell colSpan={5}>
                          <Box
                            sx={{
                              display: "flex",
                              gap: "10px",
                            }}
                          >
                            <button
                            onClick={()=>setRoleOpenModel(true)}
                              style={{
                                background: "var(--primary-color)",
                                color: "white",
                                height:'5vh',
                                border:'none',
                                borderRadius:'5px',
                                boxShadow:'1px 1px 1px 1px rgba(0,0,0,0.5)',
                                cursor:'pointer'
                              }}
                            >
                              Manage Role / Previlliages
                            </button>
                          </Box>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                  </Table>
                </TableContainer>
              </Grid>
            </Box>
          </Grid>
        <Box sx={{margin:'10px auto'}} component={Paper}>
                {
                            previlliages?.map((prev, index) => (
                                <>
                                    <RoleCardComponent
                                        key={index}
                                        prev={prev}
                                        onDeletePrevilliage={onDeletePrevilliage}
                                        onEditPrevilliage={onEditPrevilliage}
                                    />
                                </>
                            ))
                        }
                </Box>
        </Container>
      </Layout>
      <ConfirmationModel
                openCreateModel={openConfirmModel}
                onConfirm={onConfirmEditPrevilliage}
                onClose={onCancelEditPrevilliage}
                Icon={BorderColorOutlined}
                title={'Do you want to Edit?'}
            />

      {/* <Dialog
                open={roleManageOpenModel}
                onClose={() => setRoleManageOpenModel(false)}
                maxWidth={'lg'}
            >
                <DialogTitle sx={{
                    height: '10px',
                    borderRadius: "0px 0px 10px 10px",
                    background: "linear-gradient(37deg, #016AFE 41.13%, rgba(1, 163, 254, 0.53) 100%)"
                }}></DialogTitle>
                <DialogTitle
                    sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                >
                    Role Manage
                </DialogTitle>
                <DialogContent

                >
                    <Box
                        sx={{
                            // border: '1px solid black',
                            width: '30vw',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'flex-start',
                            gap: '10px',
                            margin: '10px auto'
                        }}
                    >

                        <Button variant={'outlined'} onClick={() => setRoleOpenModel(true)}>Create Role</Button>
                        {
                            previlliages.map((prev, index) => (
                                <>
                                    <RoleCardComponent
                                        key={index}
                                        prev={prev}
                                        onDeletePrevilliage={onDeletePrevilliage}
                                        onEditPrevilliage={onEditPrevilliage}
                                    />
                                </>
                            ))
                        }


                    </Box>

                </DialogContent>

            </Dialog> */}

            <Dialog
                open={roleOpenModel}
                onClose={()=>setRoleOpenModel(false)}
                maxWidth={'lg'}
            >
                {/* <DialogTitle sx={{
                    height: '10px',
                    borderRadius: "0px 0px 10px 10px",
                    
                }}></DialogTitle> */}
                <DialogTitle
                    sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', borderBottom:"2px solid var(--primary-color)" }}
                >
                    Role Manage
                </DialogTitle>
                <DialogContent

                >
                    <Box
                        sx={{
                            // border: '1px solid black',
                            width: '30vw',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'flex-start',
                            gap: '10px',
                            margin: '10px auto'
                        }}
                    >

                        <TextField
                            variant={'outlined'}
                            value={prevData.name}
                            name={'name'}
                            onChange={(e) => setPrevData((prev) => ({ ...prev, [e.target.name]: e.target.value }))}
                            label={'Role Name'}
                            fullWidth
                        />

                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Module</TableCell>
                                        <TableCell>Create</TableCell>
                                        <TableCell>Edit</TableCell>
                                        <TableCell>Delete</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>

                                    {

                                        Object.keys(prevData).map((key, index) => {
                                            if (key == 'screen') {
                                                return <PrevilliageForm
                                                    index={index}
                                                    _key={key}
                                                    prevData={prevData}
                                                    handleSwitchInput={handleSwitchInput}
                                                />
                                            }
                                            if (key == 'content') {
                                                return <PrevilliageForm
                                                    index={index}
                                                    _key={key}
                                                    prevData={prevData}
                                                    handleSwitchInput={handleSwitchInput}
                                                />
                                            }
                                            if (key == 'playlist') {
                                                return <PrevilliageForm
                                                    index={index}
                                                    _key={key}
                                                    prevData={prevData}
                                                    handleSwitchInput={handleSwitchInput}
                                                />

                                            }
                                            if (key == 'folder') {
                                                return <PrevilliageForm
                                                    index={index}
                                                    _key={key}
                                                    prevData={prevData}
                                                    handleSwitchInput={handleSwitchInput}
                                                />

                                            }
                                        })


                                    }




                                </TableBody>
                            </Table>
                        </TableContainer>




                    </Box>

                </DialogContent>
                <DialogActions>
                    <Button
                        height={'30px'}
                        width={'100px'}
                        // name={isEditMode ? 'Update' : 'Save'}
                        variant={'contained'}
                        // onClick={submitPrevilages}
                        onClick={isEditMode ? onSubmitEditPrevilliage : submitPrevilages}
                    >
                    {isEditMode ? 'Update' : 'Save'}
                    </Button>
                </DialogActions>
            </Dialog>

    </>
  );
}

export default Role;
