// import { Popover } from "@material-ui/core";
import { Dialog, DialogContent, DialogTitle,Popover} from "@mui/material";
import React from "react";

function AppWidgetForm({
  isOpen,
  onClose,
  isFullWidth,
  maxWidth,
  children,
  title,
  onOpenWidgetForm,
  id,
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    if (onClose) {
      onClose(); // Call the onClose prop to handle external state if needed
    }
  };

  // const open = Boolean(anchorEl);
  // const id = open ? 'simple-popover' : undefined;
  return (<>
  {/* <Dialog 
  maxWidth={maxWidth}
  fullWidth={isFullWidth}
   open={isOpen}
   onClose={onClose}
  >
  <DialogTitle>{title}</DialogTitle>
  <DialogContent>
    {children}
  </DialogContent>
  </Dialog> */}
  {/* <Popover
  id={id}
  open={isOpen}
  anchorEl={anchorEl}
  onClose={onClose}
  style={{top:"35vh",left:"61vw"}}
  
>
{children}
</Popover> */}
  {isOpen && (
     <div style={{
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          zIndex: 100000000000000000, // Ensure the overlay is behind the popover
        }} onClick={handleClose} />
      )}
      <Popover
        id={id}
        open={isOpen}
        anchorEl={anchorEl}
        onClose={handleClose}
        style={{top:"30%",left:"40%",
          zIndex: 100000000000000002,
        borderRadius:"8px"}} 
      >
        {children}
      </Popover>
    <div/>
  </>);
}

export default AppWidgetForm;
