import { fabClasses } from "@mui/material";
import {
  GET_USER_REQUEST,
  GET_USER_SUCCESS,
  GET_USER_FAILED,
  LOGIN_FAILED,
  LOGIN_SUCCESS,
  USER_SAVE_SUCCESS,
  LOGOUT,
  USER_FLAG_OFF,
  USER_SAVE_FAILED,
  USER_DELETE_SUCCESS,
  UPDATE_USER,
  LOGIN_REQUEST,
  CHANGE_PASSWORD_REQUEST,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_FAILED,
  REFRESH_LOGIN,
  EMAIL_TRANSFORED,
  EMAIL_TRANSFORED_FAILED,
  EMAIL_TRANSFOR_REQUEST,
  BLOCKED_USER,
  UNBLOCKED_USER,
  CONTACT_TO_ADMIN_ON_USER_BLOCKED,
  LICENS_OVER,
  EDIT_USER_REQUEST,
  EDIT_USER_SUCCESS,
} from "../constant";
const initial = {
  auth: false,
  failed: false,
  isLogging: false,
  loginSuccess: false,
  users: [],
  user: {},
  isUserLoading: false,
  isUserAdded: false,
  isAlreadyAvailable: false,
  isUserDeleted: false,
  isPasswordChanging: false,
  isPasswordChanged: false,
  done: false,
  isTimeOver: false,
  isEmailTransfored: false,
  isEmailNotTransfored: false,
  loading: false,
  isUserBlocked: false,
  loginMessage: "",
  isLicensOver: false,
};

export const userReducer = (state = initial, action) => {
  switch (action.type) {
    case LOGOUT:
      // console.log('iam logout')
      return {
        ...state,
        auth: false,
        user: {},
      };
    case USER_FLAG_OFF:
      return {
        ...state,
        isUserAdded: false,
        isAlreadyAvailable: false,
        isUserLoading: false,
        isUserDeleted: false,
        loginSuccess: false,
        failed: false,
        isLogging: false,
        isPasswordChanging: false,
        isPasswordChanged: false,
        isTimeOver: false,
        loading: false,
        isEmailTransfored: false,
        isEmailNotTransfored: false,
        isUserBlocked: false,
        isLicensOver: false,
        isProcessing: false,
        isEdited: false,
      };
    case LOGIN_REQUEST:
      return {
        ...state,
        isLogging: true,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        auth: true,
        isLogging: false,
        user: { ...action.payload.user },
        loginSuccess: true,
      };
    case LOGIN_FAILED:
      return {
        ...state,
        auth: false,
        isLogging: false,
        failed: true,
      };
    case USER_SAVE_SUCCESS:
      return {
        ...state,
        users: [...state.users, action.payload].reverse(),
        isUserAdded: true,
        isAlreadyAvailable: false,
      };
    case USER_SAVE_FAILED:
      return {
        ...state,
        isUserAdded: false,
        isAlreadyAvailable: true,
      };
    case GET_USER_REQUEST:
      return {
        ...state,
        isUserLoading: true,
      };
    case GET_USER_SUCCESS:
      return {
        ...state,
        isUserLoading: false,
        users: [...action.payload].reverse(),
      };
    case GET_USER_FAILED:
      return {
        ...state,
        isUserLoading: false,
      };
    case USER_DELETE_SUCCESS:
      return {
        ...state,
        isUserDeleted: true,
        users: state.users.filter((u) => u._id !== action.payload),
      };
    case UPDATE_USER:
      return {
        ...state,
        user: { ...action.payload },
        isUserAdded: true,
      };
    case CHANGE_PASSWORD_REQUEST:
      return {
        ...state,
        isPasswordChanging: true,
      };
    case CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        isPasswordChanging: false,
        done: true,
        isPasswordChanged: true,
      };
    case CHANGE_PASSWORD_FAILED:
      return {
        ...state,
        isPasswordChanged: false,
        isTimeOver: true,
        isPasswordChanging: false,
      };
    case REFRESH_LOGIN:
      return {
        ...state,
        auth: true,
        // isLogging:false,
        user: { ...action.payload.user },
        // loginSuccess:true
      };
    case EMAIL_TRANSFOR_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case EMAIL_TRANSFORED:
      return {
        ...state,
        loading: false,
        isEmailTransfored: true,
      };
    case EMAIL_TRANSFORED_FAILED:
      return {
        ...state,
        loading: false,
        isEmailNotTransfored: true,
      };
    case BLOCKED_USER:
      const __findIndexUpdatedUser = state.users.findIndex(
        (u) => u._id === action.payload._id
      );
      state.users[__findIndexUpdatedUser] = { ...action.payload };
      return {
        ...state,
        users: state.users,
      };
    case UNBLOCKED_USER:
      const __findIndexUpdateUnblockedUser = state.users.findIndex(
        (u) => u._id === action.payload._id
      );
      state.users[__findIndexUpdateUnblockedUser] = { ...action.payload };
      return {
        ...state,
        users: state.users,
      };

    case CONTACT_TO_ADMIN_ON_USER_BLOCKED:
      return {
        ...state,
        isUserBlocked: true,
      };
    case LICENS_OVER:
      return {
        ...state,
        isLicensOver: true,
        loginMessage: action.payload,
      };
    case EDIT_USER_REQUEST:
      return {
        ...state,
        isProcessing: true,
      };
    case EDIT_USER_SUCCESS:
      const __user_edit_index = state.users.findIndex(
        (u) => u._id == action.payload._id
      );
      state.users[__user_edit_index] = { ...action.payload };
      return {
        ...state,
        users: state.users,
        isEdited: true,
        isProcessing: false,
      };
    default:
      return state;
  }
};
