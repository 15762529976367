import { EditOutlined, Folder } from "@mui/icons-material";
import React from "react";
import folder from "../../Assets/images/flat-color-icons_folder.png";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getRouter } from "../../Redux/Actions/contentAction";
import MoreVertIcon from "@mui/icons-material/MoreVert";

import "./folder.css";
function FolderComponent({ index, _folder, _module }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const onRouter = (id) => {
    navigate(`/${_module}/${id}`);
    dispatch(getRouter(id));
  };
  const truncateName = (name) => {
    return name.length > 6 ? name.slice(0, 6) + ".." : name;
  };
  return (
    <>
      {
        <div
          onDoubleClick={() => onRouter(_folder._id)}
          key={index}
          className="folder"
          style={{
            background: "white",
            color: "black",
            border: "none",
            zIndex: "1",
            marginRight:"5px",
            marginLeft:"2px"
          }}
        >
          <MoreVertIcon 
           style={{
            position: "absolute", // Position the icon absolutely within the container
            top: "5px", // Adjust the vertical position
            right: "5px", // Adjust the horizontal position
            cursor: "pointer", // Optional: change the cursor to indicate clickability
          }}/>
          <div className="image" style={{display:"flex",alignItems:"center",justifyContent:"center"}}><img src={folder} alt="folder" /></div>
          
          <span  className="foldername" style={{display:"flex",alignItems:"center",justifyContent:"center"}}>{truncateName(_folder?.name)}</span>
          {/* <EditOutlined
            className='edit-icon'
          /> */}
        </div>
      }
    </>
  );
}

export default FolderComponent;
