import { Button, DialogTitle, TextField } from "@mui/material";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addContent } from "../../../../Redux/Actions/contentAction";
import toast from "react-hot-toast";
import AppWidgetForm from "../../component/models/AppWidgetForm";
import PreviewYoutube from "./PreviewYoutube";
import CloseIcon from "@mui/icons-material/Close";
function YoutubeWidget({onClose,folderId}) {
  const { user } = useSelector((state) => state.User);
  const dispatch = useDispatch();
  const [input, setInput] = useState({ name: "", url: "" });
  const [openPreview, setOpenPreview] = useState(false);
  const onGetInput = (e) => {
    const { value, name } = e.target;
    const verifyPlatform = value.split(".");
    // const getIdFromUrl=value.split('=');
    if (verifyPlatform.includes("youtube")) {
      setInput((prev) => ({ ...prev, [name]: value }));
      // const emUrl=`https://www.youtube.com/embed/${getIdFromUrl[1]}?rel=0&amp;autoplay=1&muted=1`
    } else {
      setInput((prev) => ({ ...prev, [name]: value }));
      console.log("its not valid ");
    }
  };
  const onSubmit = () => {
    try {
      if (input.name!=="" && input.url!=="") {
        const getIdFromUrl = input.url.split("=");
        const h = `<iframe title="Youtube Video" width="329px"
        height="288px"
        style={{ border: "1px solid black", borderRadius: "10px" }}
        src={"https://www.youtube.com/embed/{{id}}?rel=0&amp;autoplay=1&mute={{muteVal}}"}
      ></iframe>`;
        const emUrl = `https://www.youtube.com/embed/{{id}}?rel=0&amp;autoplay=1&mute={{muteVal}}`;
        const htmltostr = h
          .replace(/"/g, '\\"')
          .replace(/\n/g, "\\n")
          .toString();
        // console.log(htmltostr.replace(/\\"/g, '"').replace(/\\n/g, '\n'))
        dispatch(
          addContent({
            name: input.name,
            contenttype: "widget",
            widgetCat: "youtube",
            widgetIcon: "/images/youtubeIcon.png",
            url: emUrl
              .replace("{{id}}", getIdFromUrl[1])
              .replace("{{muteVal}}", 1),
            html: htmltostr
              .replace(/\\"/g, '"')
              .replace(/\\n/g, "\n")
              .replace("{{id}}", getIdFromUrl[1])
              .replace("{{muteVal}}", 1),
            userId: user?._id,
            folderId
          })
        );
        onClose();
      } else {
        toast.error("Please enter fileds.");
      }
    } catch (error) {
      console.log(error.message);
    }
  };
  return (
    <>
   <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            fontSize: "18px",
            fontWeight: 600,
            padding:"15px",
            marginTop:"8px"
          }}
        >
         <span>Website </span> 
         
        
          <span style={{border:"none",background:"transparent",color:"black",cursor:"pointer"}} onClick={onClose}> <CloseIcon/></span>
        </div>
      {/* <div
        style={{
          minHeight: "10dvh",
          width: "30dvw",
          display: "flex",
          justifyContent: "center",
          // border:'1px solid black'
        }}
      >
        <div
          style={{
            height: "100px",
            width: "100px",
          }}
        >
          <img
            src="/images/youtubeIcon.png"
            style={{ height: "100%", width: "100%" }}
          />
        </div>
      </div> */}
             <div
         style={{
          minHeight: "6dvh",
        fontWeight:"600",
        fontSize:"16px",
          display: "flex",
          justifyContent: "center",
          alignItems:"center"
        }}>Youtube URL</div>
      <div
       style={{
          
        width: "17dvw",
        display: "flex",
        flexDirection: "column",
        // alignItems: "center",
        padding: 20,
        gap: 10,
      }}
      >
          <span  style={{
         color:"rgba(0,0,0,0.5)",
          }}>Youtube Name</span>
        <input
          name={"name"}
          value={input.name}
          onChange={onGetInput}
          variant="outlined"
          label="Enter Name"
          placeholder="Youtube Name"
          fullWidth
          style={{ height: "35px", borderRadius:"8px", border:"1px solid rgba(0,0,0,0.3)",paddingLeft:"17px"}}
        />
        <span  style={{
         color:"rgba(0,0,0,0.5)",
          }}>Youtube Url</span>
        <input
          name={"url"}
          value={input.url}
          onChange={onGetInput}
          variant="outlined"
          label="Enter Url"
          placeholder="Youtube Url"
          fullWidth
          style={{ height: "35px", borderRadius:"8px", border:"1px solid rgba(0,0,0,0.3)",paddingLeft:"17px"}}
        />
      </div>
      <div
        style={{
          maxHeight: "10dvh",
        
          display: "flex",
          justifyContent: "center",
          padding: 10,
          gap: 10,
        }}
      >
        {/* <Button
          onClick={() => setOpenPreview(true)}
          variant={"contained"}
          title="Preview"
          style={{background:'var(--primary-color)'}}
        >
          Preview
        </Button> */}
        <Button onClick={onSubmit} variant={"contained"}  style={{background:'var(--primary-color)', width: "17dvw",height:"4vh",padding:20,borderRadius:"8px"}}  title="Submit">
          Submit
        </Button>
      </div>
      <AppWidgetForm
        maxWidth={"lg"}
        isOpen={openPreview}
        onClose={() => setOpenPreview(false)}
      >
        <PreviewYoutube
          url={input.url}
          onClose={() => setOpenPreview(false)}
          onConfirm={() => onSubmit()}
        />
      </AppWidgetForm>
    </>
  );
}

export default YoutubeWidget;
