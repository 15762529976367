import React,{useState} from 'react'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import LinkIcon from "@mui/icons-material/Link";
import { imgUrl } from '../../../api/api';
import { ReactComponent as JpgIcon } from "../../../Assets/images/jpgIcon.svg";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Divider, Menu, MenuItem } from '@mui/material';
import ScheduleSendIcon from "@mui/icons-material/ScheduleSend";
import { Folder, LanguageOutlined, ModeEditOutline } from '@mui/icons-material';
function Url({ data,addSchedule, deleteSingleContent,editContent,onOpenFolderModel }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
 
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (type, obj) => {
    setAnchorEl(null);
  };
  return (
    <>
      <div className="content-item" key={data.key}>
        <div className="content-image">
          <div className="content-del-icon">
          <MoreVertIcon
              onClick={handleClick}
              style={{ color: "var(--primary-color)" }}
              // size="small"
              // sx={{ ml: 2 }}
              aria-controls={open ? 'account-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
            />
            <Menu
              anchorEl={anchorEl}
              id="account-menu"
              open={open}
              onClose={handleClose}
              onClick={handleClose}
            >
              <MenuItem>
              <ModeEditOutline
              style={{ color: "var(--primary-color)" }}
              onClick={()=>editContent(data)}
              />
              </MenuItem>
              <Divider/>
              <MenuItem>
              
                <DeleteOutlineIcon onClick={() => deleteSingleContent(data.id)} style={{ color: "var(--primary-color)" }} />
              </MenuItem>
              <MenuItem
              onClick={()=>{
                handleClose();
                onOpenFolderModel(data.id)}}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Folder
                  sx={{ color: "var(--primary-color)", cursor: "pointer" }}
                />
              </MenuItem>
            </Menu>
          {/* <DeleteOutlineIcon  onClick={() => deleteSingleContent(data.id)} style={{ color: 'var(--primary-color)' }} /> */}
          </div>
          <div style={{width:'100%',height:'100%',display:'flex',justifyContent:'center',alignItems:'center'}}>

          <LanguageOutlined
                sx={{
                  fontSize: "17px",
                  height:'80%',
                  width:'80%',
                  // color:"var(--primary-color)"
                }}
              />
          </div>
        </div>
        <div className="content-bottom">
          <p>{data?.name?.split("--")[0]?.substring(0,20)}</p>
          <p style={{
            display: 'flex',
            alignItems: 'center'
          }}>
            <span>
              <LanguageOutlined sx={{
                fontSize: '17px',
                color:"var(--primary-color)"
              }} />
            </span>
            <span>{data.type}</span>
          </p>
        </div>
      </div>
    </>
  )
}

export default Url