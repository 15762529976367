import { useCallback } from "react";

const useFilter = (value, data) => {
  const fil = useCallback(() => {
    if (!value || value == "" || value == "") {
      return data;
    }
    let searched = data.filter((f) => {
      return Object.values(f)
        .join("")
        .toLowerCase()
        .includes(value.toLowerCase());
    });
    return searched;
  }, [value, data]);
  return fil();
};
export default useFilter;
