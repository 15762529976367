import { api } from "../../api/api"
import { CREATE_FOLDER_SUCCESS, GET_FOLDER_SUCCESS } from "../constant"

export const CreateFolder=(obj)=>{
  return async (dispatch)=>{
    try {
      
      const {status,data}=await api.post('/folder/createfolder',obj)
      if(status==201){
        dispatch({type:CREATE_FOLDER_SUCCESS,payload:data.folder})
      }
    } catch (error) {
      console.log(error.message)
    }
  }
}
export const GetFolders=(id)=>{
    return async (dispatch)=>{
        const {status,data}=await api.get(`/folder/getfolders/${id}`);
        if(status==200){
           dispatch({type:GET_FOLDER_SUCCESS,payload:data.folders})
        }
    }
}
