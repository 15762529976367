import React from 'react'
import {Box} from '@mui/material';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import BlockOutlinedIcon from '@mui/icons-material/BlockOutlined';
import {  expandedList,unExpandedList } from '../../../Redux/Actions/PrevilliageAction';
import { useDispatch } from 'react-redux';
function RoleCardComponent({ prev, index,onDeletePrevilliage,onEditPrevilliage }) {
    const dispatch=useDispatch();
    return (
        <>
            <Box
                sx={{
                    minHeight: '50px',
                    width: '100$',
                    borderRadius: '5px',
                    borderBottom: '1px solid rgba(0,0,0,.1)',

                    // border:'1px solid black'
                }}

            >

                <Box
                    sx={{
                        height: '50px',
                        width: '100%',
                        display: 'flex',
                        //    border:'1px solid black',
                        justifyContent: 'space-between',
                        alignItems: 'center'
                    }}
                >
                    <Box sx={{
                        height: '20px', minWidth: '80px', borderRadius: '20px',
                        backgroundColor: 'var(--primary-color)', color: 'white',
                        textTransform: 'capitalize',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        margin: '0 5px',
                        fontSize: '10px',
                        fontWeight: 'bold',
                        cursor: 'pointer'
                    }}
                        onClick={() => prev.isExpanded ? dispatch(unExpandedList(prev._id)) : dispatch(expandedList(prev._id))}
                    >
                        {prev?.name}
                    </Box>
                    <Box sx={{
                        width: '50%',
                        display: 'flex',
                        justifyContent: 'flex-end',
                        gap: '10px'
                    }}>
                        <BorderColorOutlinedIcon
                        sx={{cursor:'pointer'}}
                          onClick={()=>onEditPrevilliage(prev._id)}
                        />
                        <DeleteOutlineIcon
                        sx={{cursor:'pointer'}}
                            onClick={() => onDeletePrevilliage(prev._id)}
                        />
                    </Box>
                </Box>

                {
                    prev.isExpanded &&
                    <Box
                        sx={{
                            minHeight: '20vh',
                            width: '100%',
                            transition: "all .5s ease",
                            borderTop: '1px solid rgba(0,0,0,.5)',

                        }}
                    >
                        {
                            Object.keys(prev).map(key => {
                                if (key == 'screen') {
                                    return (
                                        <Box sx={{ height: '5vh', width: '100%', border: '1px solid rgba(0,0,0,.5)', display: 'flex' }}>
                                            <Box sx={{ width: '20%', height: '100%', borderRight: '1px solid rgba(0,0,0,.5)', display: 'flex', justifyContent: 'center', alignItems: 'center',textTransform:'capitalize' }}>{key}</Box>
                                            <Box sx={{ width: '80%', height: '100%', display: 'flex', flexDirection: 'column' }}>
                                                <Box sx={{ height: '50%', width: '100%', display: 'flex', borderBottom: '1px solid rgba(0,0,0,.5)' }}>
                                                    <Box sx={{ height: '100%', width: '25%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                        Create
                                                    </Box>
                                                    <Box sx={{ height: '100%', width: '25%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                        Edit
                                                    </Box>
                                                    <Box sx={{ height: '100%', width: '25%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                        Delete
                                                    </Box>
                                                </Box>
                                                <Box sx={{ height: '50%', width: '100%', display: 'flex' }}>
                                                    <Box sx={{ height: '100%', width: '25%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                        {prev[key].canCreate ? <CheckOutlinedIcon sx={{ color: 'green', fontSize: '20px' }} /> : <BlockOutlinedIcon sx={{ color: 'red', fontSize: '15px' }} />}
                                                    </Box>
                                                    <Box sx={{ height: '100%', width: '25%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                        {prev[key].canEdit ? <CheckOutlinedIcon sx={{ color: 'green', fontSize: '20px' }} /> : <BlockOutlinedIcon sx={{ color: 'red', fontSize: '15px' }} />}
                                                    </Box>
                                                    <Box sx={{ height: '100%', width: '25%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                        {prev[key].canDelete ? <CheckOutlinedIcon sx={{ color: 'green', fontSize: '20px' }} /> : <BlockOutlinedIcon sx={{ color: 'red', fontSize: '15px' }} />}
                                                    </Box>
                                                </Box>
                                            </Box>
                                        </Box>
                                    )
                                }

                                if (key == 'content') {
                                    return (
                                        <Box sx={{ height: '5vh', width: '100%', border: '1px solid rgba(0,0,0,.5)', display: 'flex' }}>
                                            <Box sx={{ width: '20%', height: '100%', borderRight: '1px solid rgba(0,0,0,.5)', display: 'flex', justifyContent: 'center', alignItems: 'center',textTransform:'capitalize' }}>{key}</Box>
                                            <Box sx={{ width: '80%', height: '100%', display: 'flex', flexDirection: 'column' }}>
                                                <Box sx={{ height: '50%', width: '100%', display: 'flex', borderBottom: '1px solid rgba(0,0,0,.5)' }}>
                                                    <Box sx={{ height: '100%', width: '25%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                        Create
                                                    </Box>
                                                    <Box sx={{ height: '100%', width: '25%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                        Edit
                                                    </Box>
                                                    <Box sx={{ height: '100%', width: '25%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                        Delete
                                                    </Box>
                                                </Box>
                                                <Box sx={{ height: '50%', width: '100%', display: 'flex' }}>
                                                    <Box sx={{ height: '100%', width: '25%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                        {prev[key].canCreate ? <CheckOutlinedIcon sx={{ color: 'green', fontSize: '20px' }} /> : <BlockOutlinedIcon sx={{ color: 'red', fontSize: '15px' }} />}
                                                    </Box>
                                                    <Box sx={{ height: '100%', width: '25%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                        {prev[key].canEdit ? <CheckOutlinedIcon sx={{ color: 'green', fontSize: '20px' }} /> : <BlockOutlinedIcon sx={{ color: 'red', fontSize: '15px' }} />}
                                                    </Box>
                                                    <Box sx={{ height: '100%', width: '25%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                        {prev[key].canDelete ? <CheckOutlinedIcon sx={{ color: 'green', fontSize: '20px' }} /> : <BlockOutlinedIcon sx={{ color: 'red', fontSize: '15px' }} />}
                                                    </Box>
                                                </Box>
                                            </Box>
                                        </Box>
                                    )
                                }

                                if (key == 'playlist') {
                                    return (
                                        <Box sx={{ height: '5vh', width: '100%', border: '1px solid rgba(0,0,0,.5)', display: 'flex' }}>
                                            <Box sx={{ width: '20%', height: '100%', borderRight: '1px solid rgba(0,0,0,.5)', display: 'flex', justifyContent: 'center', alignItems: 'center',textTransform:'capitalize' }}>{key}</Box>
                                            <Box sx={{ width: '80%', height: '100%', display: 'flex', flexDirection: 'column' }}>
                                                <Box sx={{ height: '50%', width: '100%', display: 'flex', borderBottom: '1px solid rgba(0,0,0,.5)' }}>
                                                    <Box sx={{ height: '100%', width: '25%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                        Create
                                                    </Box>
                                                    <Box sx={{ height: '100%', width: '25%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                        Edit
                                                    </Box>
                                                    <Box sx={{ height: '100%', width: '25%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                        Delete
                                                    </Box>
                                                </Box>
                                                <Box sx={{ height: '50%', width: '100%', display: 'flex' }}>
                                                    <Box sx={{ height: '100%', width: '25%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                        {prev[key].canCreate ? <CheckOutlinedIcon sx={{ color: 'green', fontSize: '20px' }} /> : <BlockOutlinedIcon sx={{ color: 'red', fontSize: '15px' }} />}
                                                    </Box>
                                                    <Box sx={{ height: '100%', width: '25%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                        {prev[key].canEdit ? <CheckOutlinedIcon sx={{ color: 'green', fontSize: '20px' }} /> : <BlockOutlinedIcon sx={{ color: 'red', fontSize: '15px' }} />}
                                                    </Box>
                                                    <Box sx={{ height: '100%', width: '25%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                        {prev[key].canDelete ? <CheckOutlinedIcon sx={{ color: 'green', fontSize: '20px' }} /> : <BlockOutlinedIcon sx={{ color: 'red', fontSize: '15px' }} />}
                                                    </Box>
                                                </Box>
                                            </Box>
                                        </Box>
                                    )
                                }

                                if (key == 'folder') {
                                    return (
                                        <Box sx={{ height: '5vh', width: '100%', border: '1px solid rgba(0,0,0,.5)', display: 'flex' }}>
                                            <Box sx={{ width: '20%', height: '100%', borderRight: '1px solid rgba(0,0,0,.5)', display: 'flex', justifyContent: 'center', alignItems: 'center',textTransform:'capitalize' }}>{key}</Box>
                                            <Box sx={{ width: '80%', height: '100%', display: 'flex', flexDirection: 'column' }}>
                                                <Box sx={{ height: '50%', width: '100%', display: 'flex', borderBottom: '1px solid rgba(0,0,0,.5)' }}>
                                                    <Box sx={{ height: '100%', width: '25%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                        Create
                                                    </Box>
                                                    <Box sx={{ height: '100%', width: '25%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                        Edit
                                                    </Box>
                                                    <Box sx={{ height: '100%', width: '25%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                        Delete
                                                    </Box>
                                                </Box>
                                                <Box sx={{ height: '50%', width: '100%', display: 'flex' }}>
                                                    <Box sx={{ height: '100%', width: '25%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                        {prev[key].canCreate ? <CheckOutlinedIcon sx={{ color: 'green', fontSize: '20px' }} /> : <BlockOutlinedIcon sx={{ color: 'red', fontSize: '15px' }} />}
                                                    </Box>
                                                    <Box sx={{ height: '100%', width: '25%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                        {prev[key].canEdit ? <CheckOutlinedIcon sx={{ color: 'green', fontSize: '20px' }} /> : <BlockOutlinedIcon sx={{ color: 'red', fontSize: '15px' }} />}
                                                    </Box>
                                                    <Box sx={{ height: '100%', width: '25%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                        {prev[key].canDelete ? <CheckOutlinedIcon sx={{ color: 'green', fontSize: '20px' }} /> : <BlockOutlinedIcon sx={{ color: 'red', fontSize: '15px' }} />}
                                                    </Box>
                                                </Box>
                                            </Box>
                                        </Box>
                                    )
                                }
                            }

                            )
                        }

                        {/* <Box sx={{height:'5vh',width:'100%',border:'1px solid black'}}></Box>
                    <Box sx={{height:'5vh',width:'100%',border:'1px solid black'}}></Box>
                    <Box sx={{height:'5vh',width:'100%',border:'1px solid black'}}></Box> */}
                    </Box>
                }

            </Box>
        </>
    )
}

export default RoleCardComponent