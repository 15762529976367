import { LanguageRounded } from "@mui/icons-material";
import {
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Button
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import AppWidgetForm from "../../component/models/AppWidgetForm"
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addContent } from "../../../../Redux/Actions/contentAction";
import toast from "react-hot-toast";
function WebsiteWidget({onClose,folderId,isOpen}) {
  const { user } = useSelector((state) => state.User);
  const dispatch = useDispatch();
  //   const {currentFolder}=useSelector(state=>state.FileFolder);
  const [items, setItems] = useState([]);
  const [name, setName] = useState("");
  const [url, setUrl] = useState("");
  const [updateInterval, setUpdateInterval] = useState("");

  //   React.useEffect(() => {
  //     handleClickOpen();
  //   }, [isOpen, onclose]);

  //   const handleClickOpen = () => {
  //     // setOpen(true);
  //     if (isOpen) {
  //       setName("");
  //       setUrl("");
  //       setUpdateInterval("");
  //     }
  //     if (onClose) {
  //       setName("");
  //       setUrl("");
  //       setUpdateInterval("");
  //     }
  //   };

  //   const handleClose = () => {
  //     // setOpen(false);
  //   };

  const handleAdd = () => {
    setItems((previous) => [...previous, { title: name, url, updateInterval }]);
    if (name == "" || url == "") {
      toast.error("(*) fileds are required");
      return;
    }
    // onTakeData({ title: name, url, updateInterval, isIcon });
    dispatch(
      addContent({
        name,
        contenttype: "widget",
        widgetCat: "website",
        widgetIcon: '/images/website.png',
        intervalValue: updateInterval,
        url: url,
        userId:user._id,
        folderId
      })
    );
    setName("");
    setUrl("");
    setUpdateInterval("");
    onClose();
    // setOpen(false);
  };
  return (
    <>
     <div className="wrap" style={{fontFamily: "Poppins, sans-serif"}}>
     <AppWidgetForm
        isOpen={true} // Adjust this as needed
        onClose={onClose}
        width="40vw" // Set custom size for this modal
        height="50vh"
        
      >
       {/* <DialogTitle
        sx={{
          height: "10px",
          borderRadius: "0px 0px 10px 10px",
          background:
            "linear-gradient(37deg, #016AFE 41.13%, rgba(1, 163, 254, 0.53) 100%)",
        }}
      ></DialogTitle> */}
      {/* <LanguageRounded
        sx={{
          color: 'gray',
          fontSize: 100,
          textAlign: "center",
          marginX: "auto",
          paddingTop: 3,
        }}
      /> */}
       <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            fontSize: "18px",
            fontWeight: 600,
            padding:"15px",
            marginTop:"8px"
          }}
        >
         <span>Website </span> 
         
        
          <span style={{border:"none",background:"transparent",color:"black",cursor:"pointer"}} onClick={onClose}> <CloseIcon/></span>
        </div>
      {/* <div
        style={{
          minHeight: "10dvh",
          width: "30dvw",
          display: "flex",
          justifyContent: "center",
          border:'1px solid black'
        }}
      >
        <div
          style={{
            height: "100px",
            width: "100px",
          }}
        >
          <img
            src="/images/website.png"
            style={{ height: "100%", width: "100%" }}
          />
        </div>
      </div> */}
       <div
         style={{
          minHeight: "6dvh",
        fontWeight:"600",
        fontSize:"16px",
          display: "flex",
          justifyContent: "center",
          alignItems:"center"
        }}>Website URL</div>
    
        <div
           style={{
          
            width: "17dvw",
            display: "flex",
            flexDirection: "column",
            // alignItems: "center",
            padding: 20,
            gap: 10,
          }}
        >
          {/* <DialogContentText sx={{ paddingTop: 3, fontWeight: "bold" }}>
            Name &#8727;
          </DialogContentText> */}
           <span  style={{
         color:"rgba(0,0,0,0.5)",
          }}>Website Name</span>
          <input
            id="outlined-basic"
            label="Your Name Here"
            placeholder="Website Name"
            variant="outlined"
            fullWidth
            style={{ height: "35px", borderRadius:"8px", border:"1px solid rgba(0,0,0,0.3)",paddingLeft:"17px"}}
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </div>
        <div
           style={{
          
            width: "17dvw",
            display: "flex",
            flexDirection: "column",
            // alignItems: "center",
            padding: 20,
            gap: 10,
          }}
        >
          {/* <DialogContentText sx={{ fontWeight: "bold" }}>
            URL &#8727;
          </DialogContentText> */}
           <span  style={{
         color:"rgba(0,0,0,0.5)",
          }}>Website URL</span>
          <input
            id="outlined-basic"
            label="URL"
            variant="outlined"
            placeholder="Website URL"
            fullWidth
            style={{ height: "35px", borderRadius:"8px", border:"1px solid rgba(0,0,0,0.3)",paddingLeft:"17px"}}

            value={url}
            onChange={(e) => setUrl(e.target.value)}
          />
        </div>
        {/* <div
          style={{
            display: "flex",
            marginTop: 20,
            alignItems: "center",
          }}
        >
          <DialogContentText sx={{ fontWeight: "bold" }}>
            Update Interval &#8727;
          </DialogContentText>
          <TextField
            id="outlined-basic"
            label="Update Interval"
            variant="outlined"
            fullWidth
            helperText="Check update every 600 seconds"
            sx={{ width: "465px" }}
            value={updateInterval}
            onChange={(e) => setUpdateInterval(e.target.value)}
          />
        </div> */}
     
      <DialogActions
        sx={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: 2,
          marginTop: 1,
        }}
      >
        {/* <div>
          <Button
            variant="contained"
            onClick={handleAdd}
            sx={{ backgroundColor: "var(--primary-color)" }}
          >
            Preview
          </Button>
        </div> */}
        <div style={{display:'flex'}}>
          {/* <Button
          variant="contained"
           onClick={() => onClose()}
           sx={{ backgroundColor: "var(--primary-color)" }}
           >Cancel</Button> */}
           <div className="btnn" style={
            { display: "flex",
              justifyContent: "center",
              alignItems:"center"
              , width: "18.5dvw"}
           }>
           <Button
            variant="contained"
            onClick={handleAdd}
            style={{background:'var(--primary-color)', width: "17dvw",height:"4vh",padding:20,borderRadius:"8px"}} 
          >
            Save
          </Button>
           </div>
         
        </div>
      </DialogActions>
      </AppWidgetForm>
     </div>
    </>
  );
}

export default WebsiteWidget;
