import React from "react";
import "./switch.css";
function CustomSwitch({name, value, onChange }) {
  return (
    <input
    name={name}
      type={"checkbox"}
      checked={value ? true : false}
      onChange={(e) => onChange(e)}
      id={'checkbox--custom'}
    />
  );
}

export default CustomSwitch;
