import {
  ADD_PLAYLIST_TO_SCREEN,
  ADD_SCREEN_REQUEST,
  ADD_SCREEN_SUCCESS,
  CLIENT_SCREENS_REQUEST,
  CLIENT_SCREENS_SUCCESS,
  DELETE_SCREEN,
  FIND_ONE_SCREEN,
  MOVE_SCREEN_TO_FOLDER_REQUEST,
  MOVE_SCREEN_TO_FOLDER_SUCCESS,
  SCREEN_FLAG_OFF,
  SCREEN_REQUEST,
  SCREEN_REQUEST_FAILED,
  SCREEN_REQUEST_SUCCESS,
  SCREEN_STATUS_ACTIVE,
  SCREEN_STATUS_DEACTIVE,
  UNCHECK_ASSIGNED_SCREEN,
  UPDATE_SCREEN_SUCCESS,
} from "../constant";
const initial = {
  screens: [],
  screen: {},
  screenLoading: false,
  isScreenAdded: false,
  isScreenUpdated:false,
  isScreenDeleted: false,
  isScreenMoved:false,
  isScreenMoving:false,
  isAdded: false,
  isUnChecked: false,
  isScreenLoaded:false,
  activeScreens:0,
  deactiveScreens:0,
  mac: '',
  clientScreens:[]
};


export const screenReducer = (state = initial, action) => {
  function findIndex(id){
    let _index= state.screens.findIndex(_screen=>_screen._id==id);
    return _index;
  }
  switch (action.type) {
    case SCREEN_FLAG_OFF:
      return {
        ...state,
        isUnChecked: false,
        isAdded: false,
        isScreenLoaded:false,
        isScreenDeleted: false,
        isScreenUpdated:false,
        isScreenMoved:false,
        isScreenMoving:false
      }
    case ADD_SCREEN_REQUEST:
      return {
        ...state,
        isScreenAdded: true,
      };
    case ADD_SCREEN_SUCCESS:
      let regiScreens={};
      const now_2 = new Date().getTime();
      let till_2 = 60*1000;
      //  let distance=now + till;
      //  action.payload.forEach(row=> {
        //  if(row.isActive){
         if(now_2 > +action.payload?.lastActive + till_2){
            regiScreens={...action.payload,onlinesStatus:false} 
            // offline_2+=1;
            state.deactiveScreens+=1;
         }
         else{
           regiScreens={...action.payload,onlinesStatus:true}
          //  online_2+=1;
          state.activeScreens+=1;
         }
      return {
        ...state,
        isScreenAdded: false,
        isAdded: true,
        activeScreens:state.activeScreens,
        deactiveScreens:state.deactiveScreens,
        screens: [...state.screens, regiScreens],
        mac: action.payload.mac
      };
      case UPDATE_SCREEN_SUCCESS:
        let updatedEditScreens={};
        const now_1 = new Date().getTime();
        let till_1 = 60*1000;
        //  let distance=now + till;
        //  action.payload.forEach(row=> {
          //  if(row.isActive){
           if(now_1 > +action.payload?.lastActive + till_1){
              updatedEditScreens={...action.payload,onlinesStatus:false} 
              // offline_1+=1;
              state.deactiveScreens+=1;
           }
           else{
             updatedEditScreens={...action.payload,onlinesStatus:true}
            //  online_1+=1;
            state.activeScreens+=1;
           }
        state.screens[findIndex(action.payload._id)]={...updatedEditScreens}
        return {
          ...state,
          screens:state.screens,
          activeScreens:state.activeScreens,
          deactiveScreens:state.deactiveScreens,
          mac:action.payload.mac,
          isScreenUpdated:true
        }
    case SCREEN_REQUEST:
      return {
        ...state,
        screenLoading: true,
      };
    case SCREEN_REQUEST_SUCCESS:
      let newScreen = []
      let updatedScreens=[];
      const now = new Date().getTime();
      let till = 60*1000;
      let online=0;
      let offline=0;
      //  let distance=now + till;
       action.payload.forEach(row=> {
        //  if(row.isActive){
          if(row?.lastActive!==undefined){
            if(now > +row?.lastActive + till){
               updatedScreens.push({...row,onlinesStatus:false}) 
               offline+=1;
            }
            else{
              updatedScreens.push({...row,onlinesStatus:true})
              online+=1;
            }
          }
        // }
       })
      return {
        ...state,
        screenLoading: false,
        isScreenLoaded:true,
        isLoaded:true,
        screens:updatedScreens,
        activeScreens:online,
        deactiveScreens:offline
      };
    case SCREEN_REQUEST_FAILED:
      return {
        ...state,
        screenLoading: false
      }
    case FIND_ONE_SCREEN:
      return {
        ...state,
        screen: { ...state.screens.find(s => s._id === action.payload), screenAssigned: false }
      }
    case ADD_PLAYLIST_TO_SCREEN:
      // const assingnedScreenIndex = state.screens.findIndex(s => s._id === action.payload._id);
      state.screens[findIndex(action.payload._id)] = { ...action.payload, screenAssigned: false }
      return {
        ...state,
        screens: state.screens,
        isUnChecked: true
      }
    case UNCHECK_ASSIGNED_SCREEN:
      const findUnCheckScreen = findIndex(action.payload)
      state.screens[findUnCheckScreen] = { ...state.screens[findUnCheckScreen], screenAssigned: true }
      return {
        ...state,
        screens: state.screens,
        isUnChecked: true
      }
    case SCREEN_STATUS_ACTIVE:
      // const activeStatusIndex = state.screens.findIndex(s => s._id === action.payload._id)
      state.screens[findIndex(action.payload._id)] = { ...action.payload }
      return {
        ...state,
        screens: state.screens,
      };
    case SCREEN_STATUS_DEACTIVE:
      // const DeactiveStatusIndex = state.screens.findIndex(s => s._id === action.payload._id)
      state.screens[findIndex(action.payload._id)] = { ...action.payload }
      return {
        ...state,
        screens: state.screens,
      };
    case DELETE_SCREEN:
      return {
        ...state,
        isScreenDeleted: true,
        screens: state.screens.filter(screen => screen._id !== action.payload)
      }
    case CLIENT_SCREENS_REQUEST:
      return {
        ...state,
        screenLoading: true,
      }
    case CLIENT_SCREENS_SUCCESS:
      let newClientScreens = []
      action.payload.forEach(s => {
        newClientScreens.push({ ...s, screenAssigned: true })
      })
      return {
        ...state,
        screenLoading: false,
        clientScreens: newClientScreens,
      };
case MOVE_SCREEN_TO_FOLDER_REQUEST:
  return {
    ...state,
    isScreenMoving:true
  }
  case MOVE_SCREEN_TO_FOLDER_SUCCESS:
    const __screen_move_index=state.screens.findIndex(s=>s._id==action.payload._id);
    state.screens[__screen_move_index]={...action.payload}
    return {
      ...state,
      isScreenMoved:true,
      screens:state.screens
    }
    default:
      return state;
  }
};
