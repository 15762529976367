import { CREATE_FOLDER_SUCCESS, GET_FOLDER_SUCCESS } from "../constant"

const initial={
    folders:[],
}
export const FolderReducer=(state=initial,action)=>{
    switch(action.type){
        case CREATE_FOLDER_SUCCESS:
            return {
                ...state,
                folders:[...state.folders,action.payload]
            }

            case GET_FOLDER_SUCCESS:
                return {
                    ...state,
                    folders:action.payload
                }
            
                default:
                    return state
    }
}