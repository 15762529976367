import { Button, DialogTitle } from '@mui/material'
import React from 'react'
function PreviewYoutubePlaylist({url,onClose,onConfirm}) {
  return (
    <>
     <DialogTitle
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          borderBottom: "3px solid var(--primary-color)",
          fontSize: "18px",
          fontWeight: 700,
        }}
      >
        Youtube/Playlist Preview
      </DialogTitle>
      <div
        style={{
          minHeight: "10dvh",
          width: "30dvw",
          display: "flex",
          justifyContent: "center",
          // border:'1px solid black'
        }}
      >
        <div
          style={{
            height: "100px",
            width: "100px",
           
          }}
        >
          <img
            src="/images/youtubePlaylistIcon.png"
            style={{ height: "100%", width: "100%" }}
          />
        </div>
      </div>

      <div
        style={{
          maxHeight: "30dvh",
          width: "30dvw",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: 10,
          gap: 10,
          
        }}
      >
     <iframe width="720" height="405" src={`https://www.youtube.com/embed/?listType=playlist&${url.split("&")[1]}&autoplay=1`} frameborder="0" allowfullscreen></iframe> 
      </div>
      <div
      style={{
        maxHeight: "10dvh",
        width: "30dvw",
        display: "flex",
        justifyContent:'center',
        padding: 10,
        gap: 10,
        
      }}
      >
        <Button onClick={onConfirm} variant={'contained'} style={{background:'var(--primary-color)'}} title="Preview">Confirm</Button>
        <Button onClick={onClose}  variant={'contained'} style={{background:'var(--primary-color)'}} title="Submit">Cancel</Button>
      </div>
    </>
  )
}

export default PreviewYoutubePlaylist
