import {
     ADD_PREVILLIAGE_RRQUEST, 
     ADD_PREVILLIAGE_SUCCESS, 
     EDIT_PREVILLIAGES, 
     GET_PREVILLIAGE_SUCCESS, 
     PREVILLIAGE_FLAG_OFF,
     PREVILLIAGE_LIST_EXPANDED,
     PREVILLIAGE_LIST_UNEXPANDED,
     REMOVE_PREVILLIAGE
} from '../constant';
const initial={
    previlliages:[],
    isPrevilliageAdding:false,
    isPrevilliageAdded:false,
    isExpandedDone:false,
}

export const PrevilliageReducer=(state=initial,action)=>{
    switch (action.type) {
        case PREVILLIAGE_FLAG_OFF:
            return {
                ...state,
                isPrevilliageAdding:false,
                isPrevilliageAdded:false,
                isExpandedDone:false
            }
            case ADD_PREVILLIAGE_RRQUEST:
                return {
                    ...state,
                    isPrevilliageAdded:true
                }

        case ADD_PREVILLIAGE_SUCCESS:
            return {
                ...state,
                isPrevilliageAdded:true,
                previlliages:[...state.previlliages,action.payload]
            }
    case GET_PREVILLIAGE_SUCCESS:
        let _listOfPrevilliages=[]
            action.payload.forEach(prev=>{
                _listOfPrevilliages.push({...prev,isExpanded:false})
            })
        return {
            ...state,
            previlliages:_listOfPrevilliages
        }
        case PREVILLIAGE_LIST_EXPANDED:
            const _indexOfPrevExp=state.previlliages.findIndex(p=>p._id===action.payload);
            state.previlliages[_indexOfPrevExp]={...state.previlliages[_indexOfPrevExp],isExpanded:true}
            console.log(action.payload,_indexOfPrevExp)
            return {
                ...state,
                isExpandedDone:true,
                previlliages:state.previlliages
            }
            case PREVILLIAGE_LIST_UNEXPANDED:
                const _indexOfPrevUnExp=state.previlliages.findIndex(p=>p._id===action.payload);
                state.previlliages[_indexOfPrevUnExp]={...state.previlliages[_indexOfPrevUnExp],isExpanded:false}
                return {
                    ...state,
                    isExpandedDone:true,
                    previlliages:state.previlliages
                }
                case REMOVE_PREVILLIAGE:
                    return {
                        ...state,
                        previlliages:state.previlliages.filter(p=>p._id!==action.payload)
                    }
                    case EDIT_PREVILLIAGES:
                        console.log(action.payload)
                        const _findIndexOfEditPrevilliage=state.previlliages.findIndex(p=>p._id===action.payload.id);
                        state.previlliages[_findIndexOfEditPrevilliage]={
                            // ...state.previlliages[_findIndexOfEditPrevilliage],
                            ...action.payload.updatedPrevilliages,
                            isExpanded:true
                        }
                        console.log(_findIndexOfEditPrevilliage,state.previlliages)
                        return {
                            ...state,
                            isExpandedDone:true,
                            previlliages:state.previlliages
                        }
        default:
            return state;
    }
}