import React from 'react'
import CustomSwitch from '../../../components/switch/CustomSwtich';
import {TableRow,TableCell,Box} from '@mui/material';
function PrevilliageForm({prevData,_key,index,handleSwitchInput}) {
    // console.log(prevData,_key)
  return (
    <>
     <TableRow key={index}>
                                                        <TableCell>
                                                            {_key}
                                                        </TableCell>
                                                        <TableCell>
                                                            <Box
                                                                sx={{
                                                                    display: "flex",
                                                                    justifyContent: "flex-start",
                                                                    alignItems: "center",
                                                                    margin: "10px 0",
                                                                    gap: "10px 10px",
                                                                }}
                                                            >
                                                                <CustomSwitch name={`${_key}-canCreate`} value={prevData[_key]?.canCreate} onChange={handleSwitchInput} />
                                                            </Box>
                                                        </TableCell>
                                                        <TableCell>
                                                            <Box
                                                                sx={{
                                                                    display: "flex",
                                                                    justifyContent: "flex-start",
                                                                    alignItems: "center",
                                                                    margin: "10px 0",
                                                                    gap: "10px 10px",
                                                                }}
                                                            >
                                                                <CustomSwitch name={`${_key}-canEdit`} value={prevData[_key]?.canEdit} onChange={handleSwitchInput} />
                                                            </Box>
                                                        </TableCell>
                                                        <TableCell>
                                                            <Box
                                                                sx={{
                                                                    display: "flex",
                                                                    justifyContent: "flex-start",
                                                                    alignItems: "center",
                                                                    margin: "10px 0",
                                                                    gap: "10px 10px",
                                                                }}
                                                            >
                                                                <CustomSwitch name={`${_key}-canDelete`} value={prevData[_key]?.canDelete} onChange={handleSwitchInput} />
                                                            </Box>
                                                        </TableCell>
                                                    </TableRow>
    </>
  )
}

export default PrevilliageForm